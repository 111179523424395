/** @jsx jsx */
import { jsx } from "theme-ui";
import { useGlobalsBoolean } from "gatsby-plugin-hfn-profile/globals";
import { Modal } from "gatsby-plugin-hfn-profile/components/Modal";
import {
  // graphql,
  navigate,
  // useStaticQuery
} from "gatsby";
import HtmlDiv from "sites-common/components/HtmlDiv";

export const NAV = "nav";

const NavBar = () => {
  const [, { off }] = useGlobalsBoolean(NAV);

  // const data = useStaticQuery(
  //   graphql`
  //     query MyQuery {
  //       allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Gatsby" } }) {
  //         nodes {
  //           items {
  //             object_slug
  //             title
  //           }
  //         }
  //       }
  //     }
  //   `
  // );
  // const { items } = data.allWordpressWpApiMenusMenusItems.nodes[0];
  // let finalMenuItem = [...items];

  // exclude mobile site from side menu
  // finalMenuItem = finalMenuItem.filter(
  //   (item) => !item.object_slug.includes("mobile")
  // );

  const finalItems = [
    // ...finalMenuItem,
    {
      object_slug: "forests-fund",
      title: "Forests Fund",
    },
    {
      object_slug: "donation-general-fund",
      title: "Donation General Fund",
    },
    {
      object_slug: "heartfulness-green",
      title: "Heartfulness Green",
    },
    {
      object_slug: "heartfulness-experience-lifes-potential-help",
      title: "Heartfulness Experience Life’s Potential (HELP)",
    },
    {
      object_slug: "green-kanha",
      title: "Adopt A Tree Initiative",
    },
    {
      object_slug: "donation-corpus-fund",
      title: "Donation Corpus Fund",
    },
    {
      object_slug: "canada-donation",
      title: "Canada Donation",
    },
    {
      object_slug: "general-donation-to-srcm-usa",
      title: "General Donation to SRCM, USA",
    },
    {
      object_slug: "general-donation-to-smsf-usa",
      title: "General Donation to SMSF, USA",
    },
    {
      object_slug: "heartfulness-institute-donation-usa",
      title: "General Donations to HI, USA",
    },
    {
      object_slug: "donation-kanha-building",
      title: "Donation for SMSF India – General Fund – Kanha Building",
    },
    {
      object_slug: "recurring-general-donation-to-srcm-usa",
      title: "General Donation to SRCM, USA – Recurring",
    },
    {
      object_slug: "recurring-general-donation-smsf-usa",
      title: "General Donation to SMSF, USA -Recurring",
    },
    {
      object_slug: "general-donations-to-heartfulness-institute-usa-recurring",
      title: "General Donation to HI, USA -Recurring",
    },
    {
      object_slug: "yoga-donations",
      title: "Heartfulness Yoga",
    },
    {
      object_slug: "kenya-donations",
      title: "Heartfulness Kenya Donations",
    },
    {
      object_slug: "donation-for-smsf-india-every-drop-counts",
      title: "Donation for SMSF India - Every Drop Counts",
    },
  ];

  return (
    <Modal
      handleClose={off}
      showCloseBtn
      position="top-right"
      style_container={{ height: "100%" }}
      style_main={{
        height: "100%",
        borderRadius: 0,
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      title="Donation Funds"
    >
      <div sx={{ mt: 3 }}>
        {finalItems.map((item) => (
          <div
            key={item.object_slug}
            role="button"
            tabIndex={0}
            aria-hidden="true"
            sx={{
              fontWeight: 500,
              cursor: "pointer",
              color: "darkgreen",
              my: 2,
              p: 2,
              bg: "#f2f9f2",
              "&:hover": { bg: "#ecf7ec" },
            }}
            onClick={() => {
              navigate(`/${item.object_slug.replace("-gatsby", "")}`);
              off();
            }}
          >
            <span sx={{ display: "inline-block" }}>
              <HtmlDiv htmlString={item.title} />
            </span>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default NavBar;
