/** @jsx jsx */
import { Button, Image, jsx, Spinner, Label } from "theme-ui";
import React, { useCallback, useEffect, useState } from "react";
import { Layout, Content, Section } from "maker-ui";
import { Select } from "gatsby-plugin-hfn-profile/components/ui";
import { graphql, useStaticQuery } from "gatsby";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";
import bannerImg from "../../static/imgs/every-drop-count-banner.png";

import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";
import Seo from "../components/seo";

require("bootstrap/dist/css/bootstrap.min.css");

const emailRegEx =
  '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';
const everyDropCountDonation = [
  {
    url: "/donation-for-smsf-india-every-drop-counts#form",
    country: "India",
  },
  {
    url: "/general-donations-to-heartfulness-institute-usa-recurring/",
    country: "United States",
  },
  {
    url: "/canada-donation#recurring",
    country: "Canada",
  },
  {
    url: "https://heartfulness.at/spenden/",
    country: "Austria",
  },
  {
    url: "https://fr.heartfulness.org/faire-un-don/",
    country: "France",
  },
  {
    url: "https://www.heartfulnessmeditation.de/spenden/",
    country: "Germany",
  },
  {
    url: "https://www.heartfulness.ie/donate/",
    country: "Ireland",
  },
  {
    url: "https://www.srcmitalia.it/public/pubblico/donazione",
    country: "Italy",
  },
  {
    url: "https://www.heartfulness.ro/despre-noi/",
    country: "Romania",
  },
  {
    url: "https://www.heartfulness.uk/donate/",
    country: "UK",
  },
  {
    url: "https://heartfulness.org/sg/donations/",
    country: "Singapore",
  },
  {
    url: "https://www.heartfulnessinstitute.org/donate",
    country: "USA",
  },
  {
    url: "https://www.one-euro-a-day.ch/en/",
    country: "Switzerland",
  },
  {
    url: "https://heartfulnessmeditation.dk/donation/",
    country: "Denmark",
  },
  {
    country: "Netherlands",
  },
  {
    country: "Sweden",
  },
  {
    country: "Australia",
  },
  {
    country: "New Zealand",
  },
  {
    country: "Japan",
  },
  {
    country: "Nepal",
  },
  {
    country: "Srilanka",
  },
  {
    country: "South Africa",
  },
  {
    country: "Malaysia",
  },
];

const renderModalClass = (toast) => {
  let classStyle;
  if (toast === "isLoading") {
    classStyle = "bg-light";
  } else if (toast.status === "success") {
    classStyle = "bg-success";
  } else {
    classStyle = "bg-danger";
  }

  return classStyle;
};

export const renderToast = (showModal, toast, onHandleClose) => (
  <div
    sx={{
      display: showModal ? "block" : "none",
      position: "fixed",
      zIndex: 5,
      paddingTop: "2%",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    }}
    className="modal"
  >
    <div
      sx={{
        position: "relative",
        backgroundColor: "#fefefe",
        margin: "auto",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
        padding: "0",
        border: " 1px solid #888",
        width: "90%",
        background: "none",
        textAlign: "initial",
      }}
      className="modal-content"
    >
      <div
        sx={{ color: "white", width: "100%" }}
        className={`modal-body ${renderModalClass(toast)}`}
      >
        <div
          role="link"
          tabIndex={0}
          aria-hidden
          className="close"
          sx={{ color: "white", cursor: "pointer", textAlign: "end" }}
          onClick={() => onHandleClose("close")}
        >
          x
        </div>
        <div>
          {toast === "isLoading" ? <Spinner size={30} /> : toast.message}
        </div>
      </div>
    </div>
  </div>
);

let emailValidation = "";
const initialValue = { country: "", emailAddress: "" };
export default function EveryDropCounts() {
  const [selectedContribution, setSelectedContribution] = useState({
    ...initialValue,
  });
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [everyDropCountDropdown] = useState(everyDropCountDonation);
  const [everyDropCountOption, setEveryDropCountOption] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsMobileSite] = usePersist("isMobileSite", false);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            everyDropcountEmailUri
          }
        }
      }
    `
  );

  useEffect(() => {
    setIsMobileSite(false);
  }, [setIsMobileSite]);

  useEffect(() => {
    let option = [];
    everyDropCountDonation.forEach((item) => {
      if (item.country !== "India") {
        option.push(item.country);
      }
    });
    option = option.sort();
    option = ["Select Country", "India", ...option];
    setEveryDropCountOption(option);
  }, [everyDropCountDropdown]);

  const handleInputChange = (value, key) => {
    emailValidation = "";

    if (key === "country") {
      const selectedOption = everyDropCountDropdown.find(
        (item) => item.country === value
      );
      if (selectedOption.url) {
        setShowEmailInput(false);
        window.location.href = selectedOption.url;
      } else {
        setShowEmailInput(true);
      }
    }

    if (key === "emailAddress") {
      if (value && !new RegExp(emailRegEx).test(value)) {
        emailValidation = "Invalid email address";
      }
    }

    setSelectedContribution({ ...selectedContribution, [key]: value });
  };

  const handleShowModal = useCallback(
    (success) => {
      if (success) {
        setSelectedContribution({ ...initialValue });
      }
      setShowModal((s) => !s);
    },
    [setShowModal]
  );

  const handleSubmit = () => {
    const postParam = { ...selectedContribution };
    if (emailValidation) {
      return;
    }
    if (showEmailInput && !postParam.emailAddress) {
      emailValidation = "Enter email address";
      setSelectedContribution({ ...selectedContribution });
      return;
    }

    const uri = new URL(site.siteMetadata.everyDropcountEmailUri);
    uri.search = new URLSearchParams(postParam).toString();

    setIsLoading((s) => !s);
    fetch(uri, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            if (json.successMessage) {
              const toastObject = {
                message: <React.Fragment>{json.successMessage}</React.Fragment>,
                status: "success",
              };
              setToast(toastObject);
              setShowEmailInput(false);
              setIsLoading((s) => !s);
              handleShowModal("success");
            } else if (json.errorMessage) {
              const toastObject = {
                message: <React.Fragment> {json.errorMessage}</React.Fragment>,
                status: "failure",
              };
              setToast(toastObject);
              setShowEmailInput(false);
              setIsLoading((s) => !s);
              handleShowModal();
            }
          });
        }
      })
      .catch(() => {
        const toastObject = {
          message: (
            <React.Fragment>
              Something went wrong, Please try again later.
            </React.Fragment>
          ),
          status: "failure",
        };
        setShowEmailInput(false);
        setToast(toastObject);
        setIsLoading((s) => !s);
        handleShowModal();
      });
  };

  return (
    <Layout theme={theme} options={options}>
      <Seo title="Recurring-India" />
      <HeaderComp />
      <Content>
        <Section>
          <Image sx={{ backgroundColor: "green" }} src={bannerImg} />
          <div
            className="container"
            sx={{
              color: "#2b2b2b",
              fontSize: "18px",
              fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
            }}
          >
            <div className="col-12">
              <div sx={{ textAlign: "center", mt: ["2rem", "2rem", "5rem"] }}>
                <p
                  sx={{
                    fontSize: ["30px", "30px", "75px"],
                    fontWeight: "bolder",
                    mb: 0,
                  }}
                >
                  Every Drop Counts
                </p>
                <p
                  sx={{
                    mb: 4,
                    fontSize: ["15px", "15px", "35px"],
                    color: "#2E9819",
                    fontFamily: "home-made-apple",
                  }}
                >
                  a golden opportunity
                </p>
              </div>
              <p className="mb-4">
                Every day, more and more people around the world are
                experiencing the Heartfulness practices and the incredible
                benefits they bring. This momentum is due to the efforts of our
                volunteers, in giving both their time and resources to sharing
                this beautiful and simple system with others.
              </p>
              <p className="mb-4">
                Kanha Shanti Vanam has emerged as a world-renowned spiritual
                wellness center, and our outreach programs in schools, colleges,
                universities, corporates, governments, villages and
                neighborhoods have helped both children and adults to manage
                stress, feel inner peace, learn to regulate their minds and
                emotions, and live purposeful and fulfilling lives.
              </p>
              <p className="mb-4">
                The number of individuals and organizations requesting our
                services is continually growing. In addition, when Kanha Shanti
                Vanam reopens, there will be an influx of more and more visitors
                who wish to rejuvenate and revitalize themselves. We will also
                restart all of our worldwide activities with renewed enthusiasm,
                e.g.:
              </p>
              <div
                sx={{
                  ml: [3, 4, 5],
                  mb: 4,
                }}
              >
                <div sx={{ marginBottom: "10px", display: "inline-block" }}>
                  - Education – this has been our most successful offering,
                  reaching millions of school, college and university students,
                </div>
                <div sx={{ marginBottom: "10px", display: "inline-block" }}>
                  - Corporations – wellness and leadership programs tailored
                  specifically for employees at various levels,
                </div>
                <div sx={{ marginBottom: "10px", display: "inline-block" }}>
                  - Government – various services at the municipal, state and
                  national level, including training Asha, Anganwadi workers and
                  Sarpanches at the village level,
                </div>
                <div sx={{ marginBottom: "10px", display: "inline-block" }}>
                  - A Covid Relief Fund,
                </div>
                <div sx={{ marginBottom: "10px", display: "inline-block" }}>
                  - The `Voice That Cares` helpline,
                </div>
                <div sx={{ marginBottom: "10px", display: "inline-block" }}>
                  - Medical programs in many ashrams,
                </div>
              </div>
              <p className="mt-4 mb-4">
                Imagine if we all participate in these efforts, no matter how
                small the amount! Daaji`s call for us to contribute INR 50 or $1
                a day for these noble causes is still current and would make a
                big difference.
              </p>
              <p className="mb-5">
                This is a golden opportunity to be part of the Heartfulness
                Movement for humanity. Please donate monthly so that we are able
                fund these programs. Every drop counts. Together we can make a
                difference, so that many more people will benefit from our
                voluntary services around the world.
              </p>

              <div
                sx={{
                  fontSize: ["16px", "16px", "28px"],
                  fontWeight: "bolder",
                  textAlign: "center",
                  color: "#798d2c",
                }}
              >
                Come, join the making of this ocean of Heartfulness!
                <br />
                Contribute, as every drop counts!
              </div>

              <div sx={{ marginTop: "40px", mb: 4 }}>
                <Select
                  sx={{
                    background: "white",
                    border: "1px solid #ced4da",
                    padding: "0 8px",
                  }}
                  name="country"
                  className="form-control"
                  value={selectedContribution.country}
                  onChange={(val) => handleInputChange(val, "country")}
                  options={everyDropCountOption}
                />
                {!showEmailInput && selectedContribution.country && (
                  <p sx={{ mt: 2, fontSize: "12px" }}>
                    You will be redirected to the appropriate donation page.
                  </p>
                )}
                {showEmailInput && (
                  <React.Fragment>
                    <div className="form-group mt-4">
                      <Label htmlFor="emailAddress">
                        Email ID:
                        <span
                          sx={{ display: "inline-block" }}
                          className="asterisk"
                        >
                          *
                        </span>
                      </Label>
                      <input
                        type="text"
                        name="emailAddress"
                        className="form-control"
                        onChange={(e) =>
                          handleInputChange(e.target.value, e.target.name)
                        }
                        value={selectedContribution.emailAddress}
                      />
                      <p sx={{ mt: 2, mb: 0, fontSize: "12px" }}>
                        Organization`s bank account details will be sent to the
                        provided e-mail id.
                      </p>
                      <span className="text-danger">{emailValidation}</span>
                    </div>
                    <Button
                      sx={{
                        display: "block",
                        width: "20%",
                        margin: "20px auto",
                        background: "#008000",
                        color: "#ffffff",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        textAlign: "center",
                        fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
                        "&:hover": { color: "#ffffff" },
                      }}
                      onClick={handleSubmit}
                    >
                      {isLoading ? (
                        <Spinner color="#ffffff" size={30} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </React.Fragment>
                )}
              </div>
              <p
                sx={{ fontSize: "15px", textAlign: "center", color: "#63767a" }}
              >
                You may email to us at{" "}
                <a href="mailto:info.accounts@sahajmarg.org">
                  info.accounts@sahajmarg.org
                </a>{" "}
                or missed call (India) at +91-89395-89295 or Call toll free
                (india) at 1800-121-3492
              </p>
              <p className="mb-4 pb-4">&nbsp;</p>
            </div>
            {renderToast(showModal, toast, handleShowModal)}
          </div>
        </Section>
      </Content>
      <FooterComp />
    </Layout>
  );
}
