/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import SiteConfig from "../../data/SiteConfig";

const Footer = ({ isGeneralFund, customText }) => {
  return (
    <div
      sx={{
        borderTop: "1px solid #c6c6c6",
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
        ...(isGeneralFund === true && {
          color: "#FFFFFF",
          height: "63px",
          backgroundColor: "#033B77",
          borderTop: "1px solid #033B77",
        }),
      }}
      className="footer"
    >
      <div sx={{ m: "2vw", textAlign: "center" }}>
        <p>{customText || SiteConfig.copyright}</p>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  isGeneralFund: false,
  customText: "",
};

Footer.propTypes = {
  isGeneralFund: PropTypes.bool,
  customText: PropTypes.string,
};

export default Footer;
